<template>
  <div class="matchLabel">
    <div class="user">客户名称：{{customer_name}}</div>
    <el-divider></el-divider>
    <div class="tab">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="关键字添加" name="first">
            <Keyword></Keyword>
        </el-tab-pane>
        <el-tab-pane label="按分类选择" name="second">
            <Classify></Classify>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Keyword from './components/Keyword.vue';
import Classify from './components/Classify.vue';

export default {
  name: 'matchLabel',
  components: {
    Keyword,
    Classify
  },
  data () {
    return {
      activeName: 'first',
      customer_name: localStorage.getItem('entityname')
    };
  },
  methods: {
    handleClick () {}
  }
};
</script>

<style lang="less" scoped>
.matchLabel {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  .tab{
      font-size: 14px;
      font-weight: 400;
      color: #666;
  }
}
</style>
